import React from 'react';
import Header from '../../components/Header/Header.jsx';
import NotFoundComponent from '../../components/NotFoundComponent/NotFoundComponent.jsx';
import Footer from '../../components/Footer/Footer.jsx';

export default function NotFound({ landingData }) {
	return (
		<>
			<Header landingData={landingData}  />
			<NotFoundComponent />
			<Footer landingData={landingData}  />
		</>
	);
}
