import React from 'react';
import style from './index.module.scss';
import { imagesMain, imagesColor } from './dataImg';
import arrow from '../../../../assets/images/sockliners-slider/arrow.svg';
import correction from '../../../../assets/images/sockliners-slider/correction-icon.svg';
import speed from '../../../../assets/images/sockliners-slider/speed-icon.svg';
import thickness from '../../../../assets/images/sockliners-slider/thickness-icon.svg';
import sizes from '../../../../assets/images/sockliners-slider/sizes-icon.svg';

export default function Winter({ landingData, toggleModal }) {
	const [activeImage, setActiveImages] = React.useState(0); // индекс активной картинки
	const [offset, setOffset] = React.useState(0); // сдвиг активной картинки в превью

	// кнопка вперед
	const handleImageNext = () => {
		if (activeImage > 0) {
			setActiveImages(0);
			setOffset(0);
		} else {
			setActiveImages(prev => prev + 1);
			setOffset(prev => prev - 72);
		}
	};

	// кнопка назад
	const handleImagePrev = () => {
		if (activeImage <= 0) {
			setActiveImages(1);
			setOffset(1 * -72);
		} else {
			setActiveImages(prev => prev - 1);
			setOffset(prev => prev + 72);
		}
	};

	// расчет сдвига в начало активной картинки в превью (72 - ширина картинки)
	const handleImagesPreview = i => {
		setActiveImages(i);
		setOffset((i === 0 ? i + 1 : i) * -72);
	};

	return (
		<div className={style.sport}>
			<div className={style.content}>
				<div className={style.column}>
					<div className={style.mainImgContainer}>
						<img
							onClick={handleImagePrev}
							className={style.prevBtn}
							src={arrow}
							alt='arrow'
						/>
						<div className={style.mainImages}>
							<img
								className={style.img}
								src={imagesMain[activeImage]}
								alt='mainImg'
							/>
						</div>
						<img
							onClick={handleImageNext}
							className={style.nextBtn}
							src={arrow}
							alt='arrow'
						/>
					</div>
					<div className={style.previewImages}>
						<div className={style.previewImagesContainer}>
							{imagesMain.map((img, i) => (
								<div
									onClick={() => handleImagesPreview(i)}
									style={{ transform: `translate3d(${offset}px, 0px, 0px)` }}
									key={i}
									className={style.imageContainer}
								>
									<div className={style.slider}>
										<img
											className={style.img}
											src={img}
											width='64'
											alt='previewImg'
										/>
										<div
											className={i === activeImage ? style.active : ''}
										></div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className={style.column2}>
					<div className={style.title}>Зимние</div>
					<p className={style.text}>
						Выполнены из флиса на каркасе из термопластика и пяточного
						амортизатора.
					</p>
					<p className={style.text}>
						Используются для профилактики и при различных заболеваниях.
					</p>
					<p className={style.text}>
						Наиболее подходят для демисезонного и зимнего времени года.
					</p>
					<p className={style.text}>Вкладываются в любую повседневную обувь.</p>
					<ul className={style.colors}>
						{imagesColor.map((img, i) => (
							<li key={i} className={style.colorItem}>
								<img src={img} alt='colorImg' />
							</li>
						))}
					</ul>
					<div className={style.correction}>
						<div className={style.icon}>
							<img src={correction} alt='' />
						</div>
						Бесплатная коррекция 30 дней
					</div>
					<div className={style.speed}>
						<div className={style.icon}>
							<img src={speed} alt='' />
						</div>
						Изготавливаются за 15 минут
					</div>
					<div className={style.thickness}>
						<div className={style.icon}>
							<img src={thickness} alt='' />
						</div>
						Толщина: <b>2 мм.</b>
					</div>
					<div className={style.sizes}>
						<div className={style.icon}>
							<img src={sizes} alt='' />
						</div>
						Размеры: <b>с 29 по 47</b>
					</div>
					<div className={style.price}>
						<div className={style.icon}>₽</div>
						Цена: <del>{landingData?.head[0]?.value} рублей</del>{' '}
						<b>{landingData?.head[1]?.value} рублей</b>
					</div>
					<div className={style.info}>
						Вторая пара по тому же слепку{' '}
						<b>{landingData?.head[2]?.value} рублей</b>
					</div>
					<div className={style.order} onClick={toggleModal}>
						Заказать
					</div>
				</div>
			</div>
		</div>
	);
}
