import slide1 from '../../../../assets/images/sockliners-slider/classic/slide1.jpg';
import slide2 from '../../../../assets/images/sockliners-slider/classic/slide2.jpg';
import slide3 from '../../../../assets/images/sockliners-slider/classic/slide3.jpg';
import slide4 from '../../../../assets/images/sockliners-slider/classic/slide4.jpg';
import slide5 from '../../../../assets/images/sockliners-slider/classic/slide5.jpg';
import slide6 from '../../../../assets/images/sockliners-slider/classic/slide6.jpg';

import color1 from '../../../../assets/images/sockliners-slider/classic/color1.jpg';
import color2 from '../../../../assets/images/sockliners-slider/classic/color2.jpg';


export const imagesMain = [
	slide1,
	slide2,
	slide3,
	slide4,
	slide5,
	slide6,
];

export const imagesColor = [color1, color2];
