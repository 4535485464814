/* eslint-disable no-undef */
import React from 'react';
import style from './index.module.scss';
import { getMaskPhone } from '../../utils/maskPhone';
import address from '../../assets/images/yandex-map/address.svg';
import time from '../../assets/images/yandex-map/time.svg';
import phone from '../../assets/images/yandex-map/phone.svg';

export default function YandexMap({ landingData }) {
	const phoneMask = getMaskPhone(landingData.phone);

	// логика отображения маршрута на карте
	const init = () => {
		// Задаём точки мультимаршрута.
		let pointA = landingData.map.coordinate_from,
			pointB = landingData.map.coordinate_to,
			multiRoute = new window.ymaps.multiRouter.MultiRoute(
				{
					referencePoints: [pointA, pointB],
					params: {
						//Тип маршрутизации - пешеходная маршрутизация.
						routingMode: 'pedestrian',
					},
				},
				{
					// тип и цвет маршрута
					routeActivePedestrianSegmentStrokeStyle: 'solid',
					routeActivePedestrianSegmentStrokeColor: '#d60623',
					// Автоматически устанавливать границы карты так, чтобы маршрут был виден целиком.
					boundsAutoApply: true,
				}
			);
		// координаты центра карты
		let center = landingData.map.coordinate_to;
		// создаем карту
		let myMap = new window.ymaps.Map(
			'map',
			{
				center: center,
				zoom: 16,
			},
			{
				buttonMaxWidth: 300,
			}
		);

		// вкл.\откл. доп информации на карте
		myMap.controls.remove('geolocationControl'); // удаляем геолокацию
		myMap.controls.remove('searchControl'); // удаляем поиск
		myMap.controls.remove('trafficControl'); // удаляем контроль трафика
		myMap.controls.remove('typeSelector'); // удаляем тип
		myMap.controls.remove('fullscreenControl'); // удаляем кнопку перехода в полноэкранный режим
		// myMap.controls.remove('zoomControl'); // удаляем контрол зуммирования
		myMap.controls.remove('rulerControl'); // удаляем контрол правил
		myMap.behaviors.disable(['scrollZoom']); // отключаем скролл карты (опционально)

		// Добавляем мультимаршрут на карту.
		myMap.geoObjects.add(multiRoute);
	};

	// вызов в функции в useEffect во избежании повторного рендера
	React.useEffect(() => {
		setTimeout(() => {
			window.ymaps.ready(init);
		}, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section id='map' className={style.map}>
			<div className={style.inner}>
				<div className={style.contacts}>
					<div className={style.wrapper}>
						<div className={style.info}>
							<div className={style.container}>
								<div className={style.containerImg}>
									<img className={style.image} src={address} alt=''></img>
								</div>
								<div className={style.title}>Адрес</div>
								<div className={style.subTitle}>{landingData.address}</div>
							</div>
							<div className={style.container}>
								<div className={style.containerImg}>
									<img className={style.image} src={time} alt=''></img>
								</div>
								<div className={style.title}>График работы</div>
								<div className={style.subTitle}>{landingData.working_time}</div>
							</div>
							<div className={style.container}>
								<div className={style.containerImg}>
									<img className={style.image} src={phone} alt=''></img>
								</div>
								<div className={style.title}>Контакты</div>
								<a
									onClick={() => ym(37051400, 'reachGoal', 'ClickPhone')}
									href={'tel:+' + landingData.phone}
									className={style.subTitle}
								>
									{phoneMask}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
