import React from 'react';
import Carousel from 'better-react-carousel';
import style from './index.module.scss';
import SliderItem from './SliderItem/SliderItem.jsx';
import ModalSlider from './ModalSlider/ModalSlider.jsx';
import { images } from './dataElementSlider';

// кастомные стрелки
const arrowLeft = () => (
	<span
		style={{
			position: 'absolute',
			top: ' calc(50% - 16.5px)',
			height: '30px',
			width: '30px',
			borderTop: '5px solid #d60623',
			borderRight: '5px solid #d60623',
			backgroundColor: '#f9f9f9',
			zIndex: '10',
			cursor: 'pointer',
			left: '0',
			right: '0',
			transform: 'rotate(225deg)',
		}}
	></span>
);
// кастомные стрелки
const arrowRight = () => (
	<span
		style={{
			position: 'absolute',
			top: ' calc(50% - 18.5px)',
			height: '30px',
			width: '30px',
			borderTop: '5px solid #d60623',
			borderRight: '5px solid #d60623',
			backgroundColor: '#f9f9f9',
			zIndex: '10',
			cursor: 'pointer',
			left: 'auto',
			right: '5px',
			transform: 'rotate(45deg)',
		}}
	></span>
);

export default function ProblemSlider({ toggleModal }) {
	const [openModal, setOpenModal] = React.useState(false); // модальное окно слайдера
	const [dataModal, setDataModal] = React.useState({}); // стейт данных активной картинки

	// передаем данные в модальное окно
	const toggleModalSlider = event => {
		setOpenModal(!openModal);
		setDataModal(event);
	};

	return (
		<>
			{openModal && (
				<ModalSlider
					toggleModal={toggleModal}
					toggleModalSlider={toggleModalSlider}
					dataModal={dataModal}
				/>
			)}
			<section className={style.problemSlider}>
				<div className={style.inner}>
					<div className={style.title}>Есть проблема?</div>
					<div className={style.line}></div>
					<div className={style.sliderContainer}>
						<div className={style.slider}>
							<Carousel
								cols={4}
								rows={2}
								gap={10}
								showDots={true}
								dotColorActive={'#d60623'}
								arrowLeft={arrowLeft}
								arrowRight={arrowRight}
								loop
							>
								{images.map(el => (
									<Carousel.Item key={el.id}>
										<SliderItem
											dataElement={el}
											toggleModalSlider={toggleModalSlider}
											openModal={openModal}
										/>
									</Carousel.Item>
								))}
							</Carousel>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
