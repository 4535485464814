import React from 'react';
import style from './index.module.scss';

export default function Faq() {
	return (
		<section className={style.faq}>
			<div className={style.inner}>
				<div className={style.titleContainer}>
					<h2 className={style.title}>Самые популярные вопросы</h2>
				</div>
				<ul className={style.list}>
					<details className={style.item}>
						<summary className={style.question}>
							Сомневаетесь, нужны ли Вам индивидуальные ортопедические стельки?
						</summary>
						<div className={style.answer}>
							<p className={style.text}>
								Вам рекомендуются стельки, если вы ответите положительно на один
								из этих пунктов:
							</p>
							<ul className={style.answerList}>
								<li className={style.answerItem}>
									Много стоите или ходите по виду деятельности
								</li>
								<li className={style.answerItem}>
									Изнашивается обувь по внутреннему или внешнему краю подошвы
								</li>
								<li className={style.answerItem}>Имеется избыточный вес</li>
								<li className={style.answerItem}>Обувь кажется неудобной</li>
								<li className={style.answerItem}>
									Есть патологи: плоскостопие, разная длина ног, вальгус или
									варус стопы, высокий свод и т.д.
								</li>
								<li className={style.answerItem}>Занимаетесь спортом</li>
								<li className={style.answerItem}>Беременность</li>
								<li className={style.answerItem}>
									Формирование стоп и опорно-двигательного аппарата у детей (от
									29 размера)
								</li>
								<li className={style.answerItem}>
									К вечеру отекают конечности
								</li>
							</ul>
						</div>
					</details>
					<details className={style.item}>
						<summary className={style.question}>
							Как часто надо менять стельки?
						</summary>
						<div className={style.answer}>
							<p className={style.text}>
								Рекомендуется менять стельки раз в полгода. Но, если снижается
								высота сводов стельки — то их нужно менять раньше. У детей
								стельки меняют еще и вместе с ростом ноги. За полгода стопа
								ребенка вырастает так, что своды уже меняются. Ребенка с
								проблемными стопами нужно показывать ортопеду раз в 6 месяцев, с
								такой же частотой следует и ходить в салон для замены стелек.
							</p>
						</div>
					</details>
					<details className={style.item}>
						<summary className={style.question}>Это больно?</summary>
						<div className={style.answer}>
							<p className={style.text}>
								Процедура не вызывает дискомфорта, более того, многие отмечали
								приятный эффект массажа стоп.
							</p>
						</div>
					</details>
				</ul>
			</div>
		</section>
	);
}
