import React from 'react';
import style from '../Features/index.module.scss';
import img_1 from '../../assets/images/stelki/features/feature11.svg';
import img_2 from '../../assets/images/stelki/features/feature12.svg';
import img_3 from '../../assets/images/stelki/features/feature13.svg';

export default function Features() {
	return (
		<section className={style.features}>
			<div className={style.inner}>
				<div className={style.listItems}>
					<div className={style.item}>
						<div className={style.containerImage}>
							<img src={img_1} alt='feature' />
						</div>
						<p>Для любой обуви взрослым и детям</p>
					</div>
					<div className={style.item}>
						<div className={style.containerImage}>
							<img src={img_2} alt='feature' />
						</div>
						<p>Изготовление 15 минут по индивидуальному слепку стопы</p>
					</div>
					<div className={style.item}>
						<div className={style.containerImage}>
							<img src={img_3} alt='feature' />
						</div>
						<p>Для здоровья, для спорта, для повседневной ходьбы</p>
					</div>
				</div>
			</div>
		</section>
	);
}
