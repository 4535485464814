import React from 'react';
import style from './index.module.scss';

export default function StepsToWrite({ landingData, toggleModal }) {
	return (
		<section className={style.stelki}>
			<div className={style.inner}>
				<div className={style.titleContainer}>
					<h2 className={style.title}>
						Мы изготовим для вас индивидуальные ортопедические стельки всего за
						15 минут!
					</h2>
				</div>
				<div className={style.row}>
					<div className={style.col}>
						<div className={style.videoWrapper}>
							<div className={style.videoContainer}>
								<iframe
									className={style.video}
									src='https://www.youtube.com/embed/tvEbkMXApz0'
									title='myFrame'
									loading='lazy'
									frameBorder='0'
									allow='accelerometer; encrypted-media; gyroscope; picture-in-picture'
									allowFullScreen
								></iframe>
							</div>
						</div>
					</div>
					<div className={style.col}>
						<h3 className={style.subtitle}>
							Запишитесь на прием к ортопеду прямо сейчас
						</h3>
						<ul className={style.list}>
							<li className={style.item}>
								<div className={style.number}>1.</div>С вами свяжутся в течение
								5 минут
							</li>
							<li className={style.item}>
								<div className={style.number}>2.</div>
								Запишитесь на удобное для Вас время
							</li>
							<li className={style.item}>
								<div className={style.number}>3.</div>
								Пройдите диагностику бесплатно!
							</li>
							<li className={style.item}>
								<div className={style.number}>4.</div>
								Получите консультацию ортопеда
							</li>
							<li className={style.item}>
								<div className={style.number}>5.</div>И заберите свою пару
								индивидуальных ортопедических стелек всего за{' '}
								{landingData.head[1].value} рублей!
							</li>
						</ul>
					</div>
				</div>
				<div className={style.button} onClick={toggleModal}>
					Записаться на прием
				</div>
			</div>
		</section>
	);
}
