import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header/Header.jsx';
import TopScreenVideo from '../TopScreenVideo/TopScreenVideo.jsx';
import Features from '../Features/Features.jsx';
import Quiz from '../Quiz/Quiz.jsx';
import ProblemSlider from '../ProblemSlider/ProblemSlider.jsx';
import Advantages from '../Advantages/Advantages.jsx';
import OrderSlider from '../OrderSlider/OrderSlider.jsx';
import StepsToWrite from '../StepsToWrite/StepsToWrite.jsx';
import Comments from '../Comments/Comments.jsx';
import Faq from '../Faq/Faq.jsx';
import Sertificates from '../Sertificates/Sertificates.jsx';
import ModalBackCall from '../ModalBackCall/ModalBackCall.jsx';
import YandexMap from '../YandexMap/YandexMap.jsx';
import Footer from '../Footer/Footer.jsx';
import Preloading from '../Preloading/Preloading.jsx';
import style from './index.module.scss';

export default function App({ landingData, isLoading }) {
	const [openModal, setOpenModal] = React.useState(false); // хук модального окна для записи
let yandex ;
let jivo ;

try {
	 yandex = landingData?.metrika[0]?.yandex;
	 jivo = landingData?.metrika[0]?.jivo;
}
catch{
	yandex='';
	jivo='';
}

	// функция изменения состояния модального окна
	const toggleModal = event => {
		// event.stopPropagation();
		setOpenModal(!openModal);
	};

	return (
		<div className={style.app}>
			{!isLoading && landingData.head ? (
				<>
					{/* библиотека динамически подставляет данные в head */}
					<Helmet>
						{jivo && <script src={jivo} type='text/javascript' async />}
						{yandex && (
							<script
								src={window.ym(yandex, 'init', {
									clickmap: true,
									trackLinks: true,
									accurateTrackBounce: true,
									webvisor: true,
								})}
								type='text/javascript'
								async
							/>
						)}
					</Helmet>

					<Header landingData={landingData} toggleModal={toggleModal} />
					{openModal && (
						<ModalBackCall
							toggleModal={toggleModal}
							landingData={landingData}
						/>
					)}
					<TopScreenVideo landingData={landingData} toggleModal={toggleModal} />
					<Features />
					<Quiz landingData={landingData} />
					<ProblemSlider toggleModal={toggleModal} />
					<Advantages />
					<OrderSlider landingData={landingData} toggleModal={toggleModal} />
					<StepsToWrite landingData={landingData} toggleModal={toggleModal} />
					<Comments landingData={landingData} />
					<Faq />
					<Sertificates />
					<YandexMap landingData={landingData} />
					<Footer landingData={landingData} toggleModal={toggleModal} />
				</>
			) : (
				<Preloading />
			)}
		</div>
	);
}
